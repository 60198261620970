import React from 'react'
import styled from 'styled-components'

import { H4, P2 } from '../Typography'
import { media } from '../Structure'

const Wrap = styled.div`
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;
`

const IconWrap = styled.div`
  height: auto;
  max-width: 50px;
  margin-right: 20px;
  ${media.phone`
    margin-right: 10px;
  `}
`

const Icon = styled.img`
  height: auto;
  color: transparent;
`

const Text = styled.div`
  width: 100%;
`

const Description = styled(P2)`
  margin-bottom: 21px;
`

const PrettyList = ({ data }) => (
  <Wrap>
    {data.map((val, ind) => (
      <Item key={ind}>
        <IconWrap>
          <Icon src={val.icon.localFile.childImageSharp.fluid.src} />
        </IconWrap>
        <Text>
          <H4 children={val.title} />
          <Description children={val.description} />
        </Text>
      </Item>
    ))}
  </Wrap>
)

export default PrettyList
