import React from 'react'
import styled from 'styled-components'

import { media } from '../Structure'
import { H3, P2 } from '../Typography'
import Button from './Button'

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  ${media.phone`
    flex-direction: column;
  `}
`

const Item = styled.div`
  padding: 30px 20px;
  box-shadow: 0 2px 14px 0 rgba(171,178,196,0.24);
  width: 100%;
  max-width: 316px;
  border-radius: 4px;
  background: white;
  ${media.phone`
    padding: 30px 10px;
    max-width: 100%;
    margin-bottom: 30px;
  `}
`

const Icon = styled.img`
  height: 117px;
  width: auto;
  margin-bottom: 18px;
`

const CardList = ({ data }) => (
  <Wrap>
    {data.map((val, ind) => (
      <Item key={ind}>
        <Icon src={val.icon.localFile.childImageSharp.fluid.src} />
        <H3 children={val.title} />
        <P2 children={val.description} />
        <Button tertiary onClick={() => alert('Outbound is coming soon… stay tuned.')} text='Learn more' />
      </Item>
    ))}
  </Wrap>
)

export default CardList
