import React from 'react'
import styled from 'styled-components'

import { Container, media } from '../Structure'
import { H2, P2, Label } from '../Typography'
import PrettyList from '../elements/PrettyList'
import Button from '../elements/Button'

const ROTATE_DEG = -8.5

const Wrap = styled.div`
  padding: 160px 0;
  background: white;
  color: #000;
  position: relative;
  transform-origin: 50% 50%;
  z-index: 500;
  transform: rotate(${ROTATE_DEG}deg);
  box-shadow: 200px 0 0 white, -200px 0 0 white;
  margin: -120px 0 0;
  ${media.phone`
    padding: 20% 0;
  `}
`

const InnerWrap = styled.div`
  position: relative;
  transform-origin: 50% 50%;
  transform: rotate(${ROTATE_DEG * -1}deg);
  display: flex;
  justify-content: flex-start;
`

const Content = styled.div`
  max-width: 487px;
`

const ButtonWrap = styled.div`
  margin: 0 -10px;
`

const Introduction = ({ label, title, description, list, buttons }) => (
  <Wrap>
    <Container>
      <InnerWrap>
        <Content>
          <Label children={label} />
          <H2 children={title} />
          <P2 children={description} />
          <PrettyList data={list} />
          <ButtonWrap>
            {/* {buttons && buttons.map((val, ind) => <Button hm {...{ [val.style]: val.style }} key={ind} link={val.link && val.link.url} text={val.text} />)} */}
            {buttons && buttons.map((val, ind) => <Button hm {...{ [val.style]: val.style }} key={ind} onClick={() => alert('Outbound is coming soon… stay tuned.')} text={val.text} />)}
          </ButtonWrap>
        </Content>
      </InnerWrap>
    </Container>
  </Wrap>
)

export default Introduction
