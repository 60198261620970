import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.min.css'

import { media } from '../Structure'
import { P2 } from '../Typography'
import Input from '../elements/Input'
import Button from '../elements/Button'
import icon_check from '../../images/icon_check.svg'

const InputWrap = styled.div`
  display: inline-block;
  margin-right: 20px;
  position: relative;
  color: #D3D3D3;
  .intl-tel-input .country-list {
    max-width: 458px;
    font-size: 17px;
    color: #000;
    border: none;
    border-radius: 4px;
    position: relative;
    top: 20px;
    ${media.tablet`
      max-width: 360px;
    `}
  }
  .intl-tel-input.allow-dropdown input {
    font-size: 20px;
    font-weight: 400;
    padding: 7px 0 7px 60px;
    letter-spacing: 1px;
  }
  .intl-tel-input .country-list::-webkit-scrollbar { 
    width: 0 !important 
  }
  ${media.tablet`
    width: 100%;
    .intl-tel-input {
      width: 100%;
      max-width: 100%;
      input {
        width: 100%;
        max-width: 100%;
      }
    }
  `}
`
const TextMe = styled.div`
  margin-bottom: 15px;
  display: flex;
  ${media.tablet`
    flex-direction: column;
  `}
  ${media.phone`
    display: none;
  `}
`
const GetTheApp = styled.div`
  display: none;
  ${media.phone`
    display: block;
  `}
`
const Message = styled.div`
  display: flex;
  align-items: center;
`
const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`
const Text = styled.div`
  font-size: 17px;
`

const TextMeTheApp = () => {

  const inputEl = useRef(null)
  const [status, setStatus] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('Free for iOS & Android')

  const handleChange = (e) => {
    window.intlInput.setNumber(e.target.value)
    setPhone(e.target.value)
  }

  const handleClick = (e) => {

    alert('Outbound is coming soon… stay tuned.')

    return

    if (handleError(window.intlInput.getValidationError()) === 'success') {
      setMessage('Sending SMS...')

      if (typeof document !== 'undefined') {
        import('branch-sdk').then(({ default: branch }) => {
          branch.init('key_live_hcDNvBYnCwOM2HCg6ZGnSonoyugZ9BSl', (err, data) => {
            console.log('Branch SDK initialized')
          })
          branch.sendSMS(window.intlInput.getNumber(), {
            tags: [],
            channel: 'Website',
            feature: 'TextMeTheApp',
          }, {}, () => {
            setStatus('success')
            console.log(`SMS sent to ${window.intlInput.getNumber()}`)
          })
        })
      }
    }
  }

  const handleError = (code) => {
    switch (code) {
      case 0: // Success
        setMessage('Free for iOS & Android')
        return 'success';
      case 2: // Too short
        setMessage('Please check your phone number and try again')
        break;
      case 3: // Too long
        setMessage('Please check your phone number and try again')
        break;
      case 4: // Not a phone number
        setMessage('Please check your phone number and try again')
        break;
      default:
    }
  }

  useEffect(() => {
    window.intlInput = intlTelInput(inputEl.current, {
      nationalMode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.1.0/js/utils.js'
    })
  }, [])

  if (status === 'success') {
    return (
      <Message>
        <Icon>
          <img src={icon_check} alt="check icon"/>
        </Icon>
        <Text>
          SMS sent! Check your phone and click on the link to download
        </Text>
      </Message>
    )
  }

  return (
    <>
      <TextMe>
        <InputWrap>
          <Input ref={inputEl} onChange={handleChange} value={phone || ''} />
        </InputWrap>
        <Button tabletBlock key='text_me_the_app' text='Text me the app' onClick={handleClick} />
      </TextMe>
      <GetTheApp>
        <a href="#" onClick={handleClick}>
          <Button phoneBlock key='text_me_the_app' white text='Get the app' />
        </a>
      </GetTheApp>
      <P2>{message}</P2>
    </>
  )
}

export default TextMeTheApp