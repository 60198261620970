import React from 'react'
import styled from 'styled-components'

import { Container, media } from '../Structure'

const Wrap = styled.div`
  padding: 90px 0 180px;
  background-color: #f7f9fc;
  color: black;
  ${media.phone`
    padding: 90px 0 170px;
    display: block;
    text-align: left;
  `}
`

const InnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.phone`
    display: block;
    text-align: left;
  `}
`

const Image = styled.img`
  max-width: 145px;
  width: 100%;
  height: 145px;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 26px;
  ${media.phone`
    max-width: 100px;
    height: 100px;
    margin: 0 0 15px 0;
  `}
`

const Text = styled.div`
  width: 100%;
`

const Message = styled.div`
  font-size: 22px;	
  font-style: italic;	
  line-height: 1.25;
  padding-right: 15%;
  margin-bottom: 20px;
  ${media.phone`
    padding: 0;
    font-size: 20px;
  `}
`

const Author = styled.div`
  font-size: 17px;	
  line-height: 1.25;
  font-weight: bold;
`

const Role = styled.div`
  font-size: 17px;	
  line-height: 1.25;
`

const Testimonials = ({ testimonial }) => (
  <Wrap>
    <Container>
      {testimonial.map((val, ind) => (
        <InnerWrap key={ind}>
          <Image src={val.image.localFile.childImageSharp.fluid.src} />
          <Text>
            <Message children={val.message} />
            <Author children={val.author} />
            <Role children={val.role} />
          </Text>
        </InnerWrap>
      ))}
    </Container>
  </Wrap>
)

export default Testimonials
