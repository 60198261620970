import styled from 'styled-components'
import { media } from './Structure'

// Headings

export const H1 = styled.h1`
  font-size: 40px;
  line-height: 1.25;
  margin-bottom: 20px;
  font-weight: bold;
  ${media.tablet`
    font-size: 36px;
  `}
  ${media.phone`
    font-size: 30px;
  `}
`

export const H2 = styled.h2`
  font-size: 34px;
  line-height: 1.25;
  margin-bottom: 20px;
  font-weight: 400;
  ${media.tablet`
    font-size: 32px;
  `}
  ${media.phone`
    font-size: 30px;
  `}
`

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 11px;
  ${media.phone`
    font-size: 20px;
  `}
`

export const H4 = styled.h4`
  font-size: 22px;
  line-height: 1.25;
  margin-bottom: 11px;
  font-weight: 600;
  ${media.phone`
    font-size: 20px;
  `}
`

// Paragraphs

export const P1 = styled.p`
  font-size: 22px;	
  line-height: 1.25;
  margin-bottom: 30px;
  ${media.tablet`
    font-size: 20px;
  `}
  ${media.phone`
    font-size: 20px;
    margin-bottom: 20px;    
    line-height: 1.2;
  `}
`

export const P2 = styled.p`
  font-size: 17px;
  line-height: 1.45;
  margin-bottom: 30px;
  ${media.phone`
    font-size: 20px;
    margin-bottom: 20px;    
    line-height: 1.2;
  `}
`

// Labels

export const Label = styled.div`
  color: #FF806E;
  font-size: 17px;	
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 12px;
  text-transform: uppercase;
`
