import React from 'react'
import styled from 'styled-components'

import { Container } from '../Structure'

const Wrap = styled.div`
  height: 136px;
  background: white;
  display: flex;
  align-items: center;
  color: #ccc;
`

const LogoBar = ({ logos }) => (
  <Wrap>
    <Container>
      {logos.map((val, ind) => (
        <a href={val.link} key={ind}>
          <img src={val.url} alt='' />
        </a>
      ))}
    </Container>
  </Wrap>
)

export default LogoBar
