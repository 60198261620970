import React from 'react'
import styled, {css} from 'styled-components'
import { Container, media, hideMixin } from '../Structure'
import { H2, P2, Label } from '../Typography'
import SimpleList from '../elements/SimpleList'

const ROTATE_DEG = -8.5

const Wrap = styled.div`
  padding: 220px 0;
  background: white;
  color: #000;
  position: relative;
  transform-origin: 50% 50%;
  /* border: solid 1px; */
  transform: rotate(${ROTATE_DEG}deg);
  z-index: 500;
  box-shadow: 200px 0 0 white, -200px 0 0 white;
  overflow: hidden;
  ${ ({bgcolor}) => bgcolor === "gray" &&`
    background: #eff5f9;
    box-shadow: 200px 0 0 #eff5f9, -200px 0 0 #eff5f9;
  `}
  ${media.tablet`
    padding: 220px 0 122px;
  `}
  ${ ({label}) => label === "reservations" && css`
    ${media.tablet`
      padding: 138px 0 165px;
    `}
  `}
  ${ ({label}) => label === "assistant" && css`
    ${media.tablet`
      padding: 180px 0 97px;
    `}
  `}
  ${ ({label}) => label === "destination guide" && css`
    padding: 140px 0;
    ${media.tablet`
      padding: 70px 0 94px;
    `}
  `}
  ${ ({label}) => label === "support" && css`
    ${media.tablet`
      padding: 182px 0 136px;
    `}
  `}
  ${ ({label}) => label === "offers" && css`
    ${media.tablet`
      padding: 90px 0 99px;
    `}
  `}
  ${ ({label}) => label === "and much more" && css`
    ${media.tablet`
      padding: 129px 0 53px;
    `}
  `}
  ${media.phone`
    padding: 20% 0;
    overflow: visible;
  `}
`

const InnerWrap = styled.div`
  position: relative;
  transform-origin: 50% 50%;
  transform: rotate(${ROTATE_DEG * -1}deg);
  display: flex;
  /* border: solid 1px red; */
  justify-content: flex-start;
  ${ ({orientation}) => orientation === "right" && css`
    flex-direction: row-reverse;
  `}
  ${media.phone`    
    flex-direction: column;
  `}
`

const Content = styled.div`
  max-width: 485px;
  position: relative;
  z-index: 500;
  ${ ({label}) => label === "reservations" && css`
    ${media.phone`
      margin-top: 35%;
    `}
  `}
  ${media.tablet`
    max-width: 360px;
  `}
  ${media.phone`
    max-width: 100%;
  `}
`

const Image = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  right: 0;
  top: 50%;
  max-width: 400px;
  transform: translateY(-50%);
  ${hideMixin}
  ${ ({orientation}) => orientation === "right" && css`
    left: 0;
    right: auto;
  `}
  ${ ({label}) => label === "reservations" && css`
    max-width: 636px;
    left: -136px;
    top: calc(50% + 29px);
    ${media.tablet`
      max-width: 513px;
      left: -122px;
      top: calc(50% + 46px);
    `}
  `}
  ${ ({label}) => label === "assistant" && css`
    top: calc(50% + 120px);
    right: 48px;
    max-width: 410px;
    ${media.tablet`
      top: calc(50% + 47px);
      right: 19px;
      max-width: 329px;
    `}
    ${media.phone`
      margin-bottom: -30%;
    `}
  `}
  ${ ({label}) => label === "destination guide" && css`
    max-width: 774px;
    top: 223px;
    left: -219px;
    ${media.tablet`
      max-width: 714px;
      top: 229px;
      left: -247px;
    `}
    ${media.phone`
      margin-top: -20%;
      margin-bottom: -30%;
    `}
  `}
  ${ ({label}) => label === "support" && css`
    max-width: 705px;
    right: -104px;
    top: calc(50% - 40px);
    ${media.tablet`
      max-width: 564px;
      right: -89px;
      top: calc(50% - 38px);
    `}
  `}
  ${ ({label}) => label === "offers" && css`
    max-width: 649px;
    top: calc(50% + 45px);
    left: -90px;
    ${media.tablet`
      max-width: 519px;
      top: calc(50% + 27px);
      left: -86px;
    `}
  `}
  ${ ({label}) => label === "and much more" && css`
    max-width: 485px;
    top: calc(50% + 120px);
    right: 5px;
    ${media.tablet`
      max-width: 387px;
      top: calc(50% + 50px);
      right: -14px;
    `}
    ${media.phone`
      margin-bottom: -35%;
    `}
  `}
  ${media.phone`    
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    transform: none;
    margin-right: auto;
    margin-left: auto;
    width: calc(100% + 24px);
    max-width: calc(100% + 24px);
    left: -12px;
    img {
      width: 100%;
    }
  `}
`

const Generic = ({ orientation, bgcolor, label, title, description, list, image, image_phone }) => (
  <Wrap bgcolor={bgcolor} label={label.toLowerCase()}>
    <Container>
      <InnerWrap orientation={orientation} label={label.toLowerCase()}>
        <Content label={label.toLowerCase()}>
          <Label children={label} />
          <H2 children={title} />
          <P2 children={description} />
          {list && (
            <SimpleList data={list} />
          )}
        </Content>
        <Image hidephone orientation={orientation} label={label.toLowerCase()}>
          <img src={image !== null && image.localFile.childImageSharp.fluid.src} alt={title} />
        </Image>
        <Image hidedesktop hidetablet orientation={orientation} label={label.toLowerCase()}>
          <img src={image !== null && image_phone.localFile.childImageSharp.fluid.src} alt={title} />
        </Image>
      </InnerWrap>
    </Container>
  </Wrap>
)

export default Generic
