import React from 'react'
import styled from 'styled-components'

import { Container, Justify, media } from '../Structure'
import LanguageSwitcher from '../elements/LanguageSwitcher'
import SocialButtons from '../elements/SocialButtons'

const Wrap = styled.div`
  background: white;
  padding: 20px 0;
  color: #9B9B9B;
`

const Copy = styled.div`
  font-size: 17px;
`

const FooterBottom = ({ wpml_translations }) => (
  <Wrap>
    <Container>
      <Justify align='center' phoneColumn phoneAlignStart>
        <Justify align='center' phoneColumn phoneAlignStart>
          {/* <div>
            <LanguageSwitcher
              wpml_translations={wpml_translations}
            />
          </div> */}
          <div>
            <SocialButtons />
          </div>
        </Justify>
        <div>
          <Copy>
            &copy; Outbound
          </Copy>
        </div>
      </Justify>
    </Container>
  </Wrap>
)

export default FooterBottom
