import React from 'react'
import styled from 'styled-components'

import { media } from '../Structure'
import { P2 } from '../Typography'

const Wrap = styled.div`
margin-bottom: 10px;
`

const Item = styled.div`
  display: flex;
`

const Icon = styled.div`
  width: 100%; 
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: linear-gradient(136.89deg, #FF5F6D 0%, #FFC371 100%);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 17px;
`

const Step = styled(P2)`
  width: 100%;
  margin-bottom: 10px;
  ${media.phone`
    font-size: 17px;
    line-height: 1.3
  `}
`

const PrettyList = ({ data }) => (
  <Wrap>
    {data.map((val, ind) => (
      <Item key={ind}>
        <Icon>
          {ind + 1}
        </Icon>
        <Step children={val.text} />
      </Item>
    ))}
  </Wrap>
)

export default PrettyList
