import React from 'react'
import styled from 'styled-components'

import { media } from '../Structure'
import circles_1 from '../../images/circles_1.svg'
import circles_2 from '../../images/circles_2.svg'
import circles_3 from '../../images/circles_3.svg'
import circles_4 from '../../images/circles_4.svg'
import circles_5 from '../../images/circles_5.svg'

const Circle = styled.div`
  position: absolute;
  z-index: 1000;
  ${media.tablet`
    display: none;
  `}
  ${media.phone`
    display: none;
  `}
`
const Circle1 = styled(Circle)`
  width: 181px;
  height: 171px;
  background: url(${circles_1}) no-repeat center center;
  right: 15px;
  top: 920px;
`
const Circle2 = styled(Circle)`
  width: 197px;
  height: 133px;
  background: url(${circles_2}) no-repeat center center;
  top: 1710px;
  left: 15px;
`
const Circle3 = styled(Circle)`
  width: 133px;
  height: 146px;
  background: url(${circles_3}) no-repeat center center;
  right: 0;
  top: 2760px;
`
const Circle4 = styled(Circle)`
  width: 128px;
  height: 127px;
  background: url(${circles_4}) no-repeat center center;
  top: 3600px;
`
const Circle5 = styled(Circle)`
  width: 222px;
  height: 167px;
  background: url(${circles_5}) no-repeat center center;
  top: 4150px;
  right: 0;
`

const Circles = () => {
  return (
    <>
      <Circle1 />
      <Circle2 />
      <Circle3 />
      <Circle4 />
      <Circle5 />
    </>
  )
}

export default Circles