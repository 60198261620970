import React from 'react'
import styled from 'styled-components'

import { Container, media } from '../Structure'
import { H1, H3, P1, P2 } from '../Typography'

const Wrap = styled.div`
  padding-top: 120px;
  color: #000;
  position: relative;
  background: white;
  margin-bottom: 80px;
  ${media.tablet`
    margin-bottom: 25px;
  `}
  ${media.phone`
    padding-top: 85px;
    margin-bottom: 0;
  `}
`
const Top = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 92px;
  ${media.tablet`
    margin-bottom: 80px;
  `}
  ${media.phone`
    text-align: left;
    margin-bottom: 60px;
  `}
`
const Questions = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Question = styled.div`
  width: 50%;
  padding-right: 20px;
  margin-bottom: 30px;
  ${media.phone`
    width: 100%;
  `}
  ${media.phone`
    text-align: left;
    margin-bottom: 40px;
    padding: 0;
  `}
`
const Title = styled(H3)`
  margin-bottom: 20px;
  ${media.tablet`
    font-size: 20px;
  `}
`
const Description = styled(P2)`
  ${media.phone`
    font-size: 17px;
    line-height: 1.42;
  `}
`

const Help = ({ title, description, questions }) => (
  <Wrap>
    <Container>
      <Top>
        <H1>{title}</H1>
        <P1>{description}</P1>
      </Top>
      {questions && (
          <Questions>
            {questions.map( (val, ind) => {
              return (
                <Question>
                  <Title>{val.title}</Title>
                  <Description>{val.description}</Description>
                </Question>
              )
            })}
          </Questions>
      )}
    </Container>
  </Wrap>
)

export default Help
