import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Image from './Image'

const Wrap = styled(Link)`
  display: block;
  width: 145px;
  height: 20px;
`

const Logo = ({ white, black, color }) => {
  let src

  if (white) {
    src = 'logo_white.png'
  }
  if (black) {
    src = 'logo_black.png'
  }
  if (color) {
    src = 'logo_color.png'
  }

  return (
    <Wrap to='/'>
      <Image src={src} />
    </Wrap>
  )
}

export default Logo
