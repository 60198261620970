import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Image from './Image'
import { media } from '../Structure'

const Wrap = styled.div`
`

const Icon = styled.a`
  display: inline-block;
  width: 36px;
  height: 36px;
  margin: 0 10px;
  ${media.phone`
    margin: 20px 20px 20px 0;
  `}
`

const SocialButtons = () => {
  const { site } = useStaticQuery(query)

  const social = site.siteMetadata.social

  return (
    <Wrap>
      <Icon target='_blank' href={social.facebook}>
        <Image src='icon_facebook.png' />
      </Icon>
      <Icon target='_blank' href={social.twitter}>
        <Image src='icon_twitter.png' />
      </Icon>
      <Icon target='_blank' href={social.instagram}>
        <Image src='icon_instagram.png' />
      </Icon>
      <Icon target='_blank' href={social.pinterest}>
        <Image src='icon_pinterest.png' />
      </Icon>
    </Wrap>
  )
}

const query = graphql`
query {
  site {
    siteMetadata {
      social {
        facebook
        twitter
        instagram
        pinterest
      }
    }
  }
}
`

export default SocialButtons
