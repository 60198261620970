import React from 'react'
import styled from 'styled-components'

import { Container, media, hideMixin } from '../Structure'
import { H2, P2, Label } from '../Typography'
import StepList from '../elements/StepList'
import Button from '../elements/Button'
// import Image from '../elements/Image'

const ROTATE_DEG = -8.5

const Wrap = styled.div`
  padding: 145px 0;
  margin-top: -105px;
  background: white;
  color: #000;
  position: relative;
  transform-origin: 50% 50%;
  z-index: 600;
  transform: rotate(${ROTATE_DEG}deg);
  box-shadow: 200px 0 0 white, -200px 0 0 white;
  ${media.tablet`
    padding: 115px 0 22px;
  `}
  ${media.phone`
    padding: 20% 0;
  `}
`

const InnerWrap = styled.div`
  position: relative;
  transform-origin: 50% 50%;
  transform: rotate(${ROTATE_DEG * -1}deg);
  display: flex;
  justify-content: flex-start;
  ${media.phone`
    flex-direction: column;
  `}
`

const Content = styled.div`
  max-width: 487px;
  position: relative;
  ${media.tablet`
    max-width: 360px;
  `}
  ${media.phone`
    max-width: 100%;
  `}
`

const ImagePhone = styled.img`
  ${hideMixin}
  ${media.phone`
    width: 130%;
    max-width: 130%;
    left: -15%;
    position: relative;
    margin-bottom: -60%;
  `}
`

const ImageDemo = styled.img`
  position: absolute;
  width: 466px;
  height: auto;
  right: 11px;
  top: -114px;
  ${media.tablet`
    display: none;
  `}
  ${media.phone`
    display: none;
  `}
`

const ButtonWrap = styled.div`
  margin: 0 -10px;
`

const Setup = ({ label, title, description, step_list, image, image_phone, buttons }) => (
  <Wrap>
    <Container>
      <InnerWrap>
      <Content>
        {/* <Arrow>
          <Image src='detail_arrow.png' />
        </Arrow> */}
        <Label children={label} />
          {label == 'Easy Setup' ? (
            <>
              <H2 children={title} />
              <StepList data={step_list} />
              <P2 children={description} />
            </>
          ) : (
            <>
              <H2 children={title} />
              <P2 children={description} />
              <StepList data={step_list} />
            </>
          )}
        <ButtonWrap>
          {/* {buttons && buttons.map((val, ind) => <Button hm {...{ [val.style]: val.style }} key={ind} link={val.link && val.link.url} text={val.text} />)} */}
          {buttons && buttons.map((val, ind) => <Button hm {...{ [val.style]: val.style }} key={ind} onClick={() => alert('Outbound is coming soon… stay tuned.')} text={val.text} />)}
        </ButtonWrap>
      </Content>
      { image && <ImageDemo src={image.localFile.childImageSharp.fluid.src} /> }
      { image_phone && <ImagePhone hidedesktop hidetablet src={image_phone.localFile.childImageSharp.fluid.src} /> }
      </InnerWrap>
    </Container>
  </Wrap>
)

export default Setup
