import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  display: block;
  max-width: 100%;
  width: 285px;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  padding: 10px 20px;
  display: inline-block;
  text-transform: uppercase;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  border: none;
  ${({ mr }) => mr && `
    margin-right: 16px;
  `}
  ${({ ib }) => ib && `
    display: inline-block;
  `}
`

export default Input
