import React from 'react'
import styled from 'styled-components'

import { Container, Justify, media } from '../Structure'

import Logo from '../elements/Logo'
import Image from '../elements/Image'
import MenuFooter from '../menus/MenuFooter'

const Wrap = styled.div`
  display: block;
  background: white;
  color: black;
`
const InnerWrap = styled.div`
  padding: 48px 0 30px;
  border-bottom: solid 1px #0000001a;
  ${media.tablet`
    padding: 37px 0 15px;
  `}
`
const LogoWrap = styled.div`
  margin-bottom: 25px;
`
const AppStore = styled.div`
  display: flex;
  ${media.tablet`
    flex-direction: column;
  `}
  ${media.phone`
    flex-direction: row;
    margin: 0 -5px 40px;
  `}
`
const Banner = styled.a`
  display: block;
  width: 150px;
  height: 44px;
  margin-right: 20px;
  ${media.tablet`
    margin-bottom: 15px;
  `}
  ${media.phone`
    height: auto;
    width: 100%;
    margin: 0 5px;
  `}
`

const Footer = () => {
  return (
      <>
        <Wrap>
          <Container>
            <InnerWrap>
              <Justify phoneColumn>
                <div>
                  <LogoWrap>
                    <Logo black />
                  </LogoWrap>
                  <AppStore>
                    <Banner href='!#' onClick={() => alert('Outbound is coming soon… stay tuned.')}>
                      <Image src='badge_app_store.png' onClick={() => alert('Outbound is coming soon… stay tuned.')} />
                    </Banner>
                    <Banner href='#!'>
                      <Image src='badge_google_play.png' />
                    </Banner>
                  </AppStore>
                </div>
                <MenuFooter />
              </Justify>
            </InnerWrap>
          </Container>
        </Wrap>
      </>
  )
}

export default Footer
