import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { media } from '../Structure'
import Button from '../elements/Button'

const Wrap = styled.div`
  display: flex; 
  width: 100%;
  max-width: 492px;
  justify-content: space-between;
  color: #000;
  ${media.tablet`
    padding-right: 70px;
  `}
  ${media.phone`
    padding-right: 0;
    flex-wrap: wrap;
  `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.phone`
    width: 50%;
    margin-bottom: 40px;
  `}
`

const TitleLink = styled(Link)`
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  line-height: 1;
  font-size: 18px;
  color: black;
`

const ButtonLink = styled(Button)`
  margin-bottom: 10px;
`

const MenuFooter = () => {

  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(query)

  const menuItems = allWordpressWpApiMenusMenusItems.edges['0'].node.items

  return (
    <Wrap>
      {menuItems.map((val, ind) => (
        <Column key={ind}>
          <TitleLink key={ind} to={`${val.url}`}>
            {val.title}
          </TitleLink>
          {val.wordpress_children.map((val, ind) => (
            <ButtonLink footer link={val.url} text={val.title} />
          ))}
        </Column>
      ))}
    </Wrap>
  )
}

const query = graphql`
  query MenuFooterQuery {
    allWordpressWpApiMenusMenusItems {
      edges {
        node {
          name
          count
          items {
            title
            url
            wordpress_children {
              title
              url
            }
          }
        }
      }
    }
  }
`

export default MenuFooter
