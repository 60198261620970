import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import styled, {css} from 'styled-components'
import Store from '../../utils/Store'

import { Container, Justify, media, hideMixin } from '../Structure'
import Logo from '../elements/Logo'
import iconClose from '../../images/icon_close.svg'
import iconBurger from '../../images/icon_burger.svg'

const Wrap = styled.div`
  animation: show .3s ease;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2000;
  color: #796FFF;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.08);
  padding: 20px 0;
  z-index: 2000;
  ${({ animate }) => animate && `
    animation: hide .3s ease;
    top: -83px;
  `}
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  ${media.phone`
    left: 12px;
    right: 12px;
    margin: 0 auto;
    max-width: calc(100% - 24px);
    top: 5px;
    flex-direction: column;
    position: fixed;
    background: white;
    border-radius: 4px;
    box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.08);
    padding: 20px 10px;
    ${({ mobileMenuDisplay }) => mobileMenuDisplay && `
      display: ${mobileMenuDisplay};
      z-index: 3000;
    `}
  `}
`

const ItemStyles = css`
  font-size: 15px;
  margin-left: 40px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  color: #796FFF;
  ${hideMixin}
  ${({ classes }) => classes === 'cta' && `
    border: solid 1px;
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    background-color: #796FFF;
    &:hover {
      background-color: #6058cc;
    }
    &:active {
      color: white;
      background-color: #484299;
    }
  `}
  ${media.phone`
    color: #796FFF;
    margin: 10px 0;
    ${({ classes }) => classes === 'cta' && `
        width: 100%;
        display: block;
        text-align: center;
        background-color: white;
    `}
  `}
`

const ItemLink = styled(Link)`
  ${ItemStyles}
`

const ItemAnchor = styled.a`
  ${ItemStyles}
`

const PhoneTitle = styled.div`
  display: none;
  color: #000000;	
  font-size: 17px;	
  line-height: 1.25;
  margin-bottom: 10px;
  ${media.phone`
    display: block;
  `}
`

const PhoneMenuOpen = styled.div`
  display: none;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  ${media.phone`
    display: block;
  `}
`

const PhoneMenuClose = styled.div`
  display: none;
  position: absolute;
  color: #796FFF;
  right: 12px;
  font-weight: bold;
  font-size: 24px;
  top: 18px;
  line-height: 1;
  ${media.phone`
    display: block;
  `}
`

const MenuFixed = ({ menuItems }) => {
  const { title } = useContext(Store)
  const [status, setStatus] = useState('hide')
  const [animate, setAnimate] = useState(false)
  const [mobileMenuDisplay, setMobileMenuDisplay] = useState('none')

  const showMenu = () => {
    setMobileMenuDisplay('flex')
  }

  const hideMenu = () => {
    setMobileMenuDisplay('none')
  }


  // Must check for undefined, otherwise all hell breaks
  // lose when gatsby tries to make a prod build with node

  if (typeof document !== 'undefined') {
    // Fixed Header Handling
    let prevScroll = document.documentElement.scrollTop

    useEffect(() => {
      const handleScroll = () => {
        let currentScroll = document.documentElement.scrollTop

        if (currentScroll > prevScroll && currentScroll > 350) {
          setStatus('show')
          console.log(status)
        }

        if (currentScroll < prevScroll) {
          // animate
          setAnimate(true)
          // effectivelly hide
          setTimeout(() => {
            setStatus('hide')
            setAnimate(false)
          }, 300)
        }

        prevScroll = currentScroll
      }

      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])
  }

  return status === 'show' && (
    <Wrap animate={animate}>
      <Container>
        <Justify align='center'>
          <Logo color />
          <PhoneMenuOpen onClick={showMenu}>
            <img src={iconBurger} alt='Open mobile menu' />
          </PhoneMenuOpen>
          <Nav mobileMenuDisplay={mobileMenuDisplay}>
            <PhoneMenuClose onClick={hideMenu}>
              <img src={iconClose} alt='Close mobile menu' />
            </PhoneMenuClose>
            <PhoneTitle>
              {title}
            </PhoneTitle>
            <ItemLink hidedesktop hidetablet to='/' children='Home' />
            {menuItems.map(val => (
              val.wordpress_children.map((val, ind) => (
                val.url.includes('http') || val.url.includes('mailto') ? (
                  <ItemAnchor
                    classes={val.classes}
                    key={ind}
                    activeStyle={{ borderBottom: 'solid 1px' }}
                    href={`${val.url}`}
                    target="_blank"
                    children={val.title}
                  />
                ) : (
                  <ItemLink
                    classes={val.classes}
                    key={ind}
                    activeStyle={{ borderBottom: 'solid 1px' }}
                    to={`${val.url}`}
                    children={val.title}
                  />
                )
              ))
            ))}
          </Nav>
        </Justify>
      </Container>
    </Wrap>
  )
}

export default MenuFixed
