import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Store from '../../utils/Store'

import { media } from '../Structure'
import Logo from '../elements/Logo'
import Button from '../elements/Button'
import iconClose from '../../images/icon_close.svg'
import iconBurger from '../../images/icon_burger.svg'
import iconBurgerWhite from '../../images/icon_burger_white.svg'

const Nav = styled.div`
  display: flex;
  align-items: center;
  ${media.phone`
    left: 12px;
    right: 12px;
    margin: 0 auto;
    max-width: 456px;
    top: 5px;
    flex-direction: column;
    position: fixed;
    background: white;
    border-radius: 4px;
    box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.08);
    padding: 20px 10px;
    ${({ mobileMenuDisplay }) => mobileMenuDisplay && `
      display: ${mobileMenuDisplay};
      z-index: 3000;
    `}
    a {
      display: block;
      width: 100%;
      margin-top: 15px;
      button {
        color: #796FFF;
        display: block;
        width: 100%;
        margin: 0;
      }
    }
  `}
`

const PhoneTitle = styled.div`
  display: none;
  color: #000000;	
  font-size: 17px;	
  line-height: 1.25;
  margin-bottom: 10px;
  ${media.phone`
    display: block;
    width: 100%;
    text-align: center;
    border-bottom: solid 1px rgba(0,0,0,.1);
    margin-bottom: 0;
    padding-bottom: 15px;
  `}
`

const PhoneMenuOpen = styled.div`
  display: none;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  ${media.phone`
    display: block;
  `}
`

const PhoneMenuClose = styled.div`
  display: none;
  position: absolute;
  color: #796FFF;
  right: 12px;
  font-weight: bold;
  font-size: 24px;
  top: 18px;
  line-height: 1;
  ${media.phone`
    display: block;
  `}
`

const MenuParent = ({ menuItems }) => {
  const { colorScheme, title, location } = useContext(Store)

  const [mobileMenuDisplay, setMobileMenuDisplay] = useState('none')

  const showMenu = () => {
    setMobileMenuDisplay('flex')
  }

  const hideMenu = () => {
    setMobileMenuDisplay('none')
  }

  return (
    <>
      {colorScheme === 'light_gray' ? <Logo color /> : <Logo white />}
      <PhoneMenuOpen onClick={showMenu}>
        <img src={colorScheme === 'light_gray' ? iconBurger : iconBurgerWhite} alt='Open mobile menu' />
      </PhoneMenuOpen>
      <Nav mobileMenuDisplay={mobileMenuDisplay}>
        <PhoneMenuClose onClick={hideMenu}>
          <img src={iconClose} alt='Close mobile menu' />
        </PhoneMenuClose>
        <PhoneTitle>
          {title}
        </PhoneTitle>
        {location.pathname !== '/' && <Button menu hidedesktop link="/" text="Home" />}
        {menuItems.map(val => (
          val.wordpress_children.map((val, ind) => {

            if (val.classes === 'cta' &&  colorScheme !== 'light_gray') {
              return <Button lineAlt link={val.url} text={val.title} />
            }

            if (val.classes === 'cta') {
              return <Button line link={val.url} text={val.title} />
            }

            if (colorScheme !== 'light_gray') {
              return <Button menuAlt link={val.url} text={val.title} />
            }

            return <Button menu link={val.url} text={val.title} />
            
          })
        ))}
      </Nav>
    </>
  )
}

export default MenuParent
