import React from 'react'
import styled from 'styled-components'

import { Container, media } from '../Structure'
import { H2, P1, Label } from '../Typography'
import CardList from '../elements/CardList'

const Wrap = styled.div`
  padding: 150px 0;
  background: #f7f9fc;
  color: #000;
  text-align: center;
  ${media.phone`
    padding: 20% 0;
    display: block;
    text-align: left;
  `}
`

const Icon = styled.img`
  display: inline-block;
	height: 82px;
	width: 82px;
  margin-bottom: 20px;
  ${media.phone`
    display: none;
  `}
`

const Intro = styled(P1)`
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 70px;
`

const Introduction = ({ label, title, description, card_list }) => (
  <Wrap>
    <Container>
      <Label children={label} />
      <H2 children={title} />
      <Intro children={description} />
      <CardList data={card_list} />
    </Container>
  </Wrap>
)

export default Introduction
