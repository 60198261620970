import React, { useContext } from 'react'
import styled from 'styled-components'

import Store from '../utils/Store'

import Circles from '../components/elements/Circles'
import Hero from '../components/sections/Hero'
import Introduction from '../components/sections/Introduction'
import Features from '../components/sections/Features'
import LogoBar from '../components/sections/LogoBar'
import Testimonials from '../components/sections/Testimonials'
import Setup from '../components/sections/Setup'
import CTA from '../components/sections/CTA'
import Generic from '../components/sections/Generic'
import Help from '../components/sections/Help'

const Wrap = styled.div`
  overflow: hidden;
`

const Layout = ({ data }) => {

  const { location } = useContext(Store)

  return (
    <Wrap>
      {data !== null && (
        <>

          {location.pathname === '/' && <Circles />}

          {data.map((val, ind) => {
            switch (val.__typename) {
              case 'WordPressAcf_hero':
                return <Hero {...val} key={ind} />

              case 'WordPressAcf_introduction':
                return <Introduction {...val} key={ind} />

              case 'WordPressAcf_features':
                return <Features {...val} key={ind} />

              // case 'WordPressAcf_logo_bar':
              //   return <LogoBar {...val} key={ind} />

              case 'WordPressAcf_testimonials':
                return <Testimonials {...val} key={ind} />

              case 'WordPressAcf_setup':
                return <Setup {...val} key={ind} />

              case 'WordPressAcf_cta':
                return <CTA {...val} key={ind} />

              case 'WordPressAcf_generic':
                return <Generic {...val} key={ind} />

              case 'WordPressAcf_help':
                return <Help {...val} key={ind} />

              default:
                return null
            }
          })}
        </>
      )}
    </Wrap>
  )
}

export default Layout
