import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Store from '../../utils/Store'

import { Container } from '../Structure'
import MenuParent from '../menus/MenuParent'
import MenuFixed from '../menus/MenuFixed'
import Breadcrumbs from '../menus/Breadcrumbs'

const Wrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2000;
`

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`

const Header = ({ wordpress_id, wordpress_parent, wpml_current_locale }) => {
  // Acess global location object
  const { location } = useContext(Store)

  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(query)

  // TODO: Refactor this a more dynamic way
  let menuItems
  menuItems = allWordpressWpApiMenusMenusItems.edges['1'].node.items
    
  const currentMenuItems = menuItems.filter(val => val.object_id === wordpress_id || val.object_id === wordpress_parent)

  return (
    <Wrap>
      <Container>
        <Section>
          <MenuParent
            menuItems={currentMenuItems}
          />
        </Section>
        <MenuFixed
          menuItems={currentMenuItems}
        />
        { location.pathname !== '/' && <Breadcrumbs /> }
      </Container>
    </Wrap>
  )
}

const query = graphql`
  query MenuQuery {
    allWordpressWpApiMenusMenusItems {
      edges {
        node {
          name
          count
          items {
            order
            title
            url
            object_id
            wordpress_children {
              wordpress_parent
              wordpress_id
              title
              classes
              url
            }
          }
        }
      }
    }
  }
`

export default Header
