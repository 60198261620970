import React, { useContext } from 'react'
import styled from 'styled-components'

import Store from '../../utils/Store'
import { Container, media } from '../Structure'
import { H1, P1 } from '../Typography'
import TextMeTheApp from '../elements/TextMeTheApp'
import Button from '../elements/Button'
import gradient_gray from '../../images/detail_gradient_1.svg'
import gradient_orange from '../../images/detail_gradient_3.svg'
import gradient_circle from '../../images/detail_gradient_4.svg'

const Wrap = styled.div`
  padding: 140px 0 118px;
  background: linear-gradient(135deg, #FF5F6D 0%, #FF7A6E 55.16%, #FFA570 100%);
  min-height: 520px;
  position: relative;
  ${({ colorScheme }) => colorScheme === 'light_gray' && `
    color: black;
    background: linear-gradient(132.17deg, #F7F9FC 0%, #F7F9FC 100%);
  `}
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(${gradient_orange}) no-repeat center -26%;
    ${({ colorScheme }) => colorScheme === 'light_gray' && `
      background: url(${gradient_gray}) no-repeat calc(50% - 223px) center;
    `}
  }
  ${media.tablet`
    padding: 120px 0 91px;
    max-width: 100%;
    min-height: 0;
  `}
  ${media.phone`
    padding: 85px 0 140px;
    max-width: 100%;
    min-height: 0;
    &::before {
      background: url(${gradient_circle}) no-repeat calc(50% - 210px) calc(100% + 75px);
      background-size: 990px;
      ${({ colorScheme }) => colorScheme === 'light_gray' && `
        background: url(${gradient_gray}) no-repeat calc(50% - 223px) center;
      `}
    }    
  `}
`
const InnerWrap = styled.div`
  ${media.tablet`
    max-width: 360px;
  `}
  ${media.phone`
    max-width: 100%;
  `}
`
const Description = styled(P1)`
  max-width: 500px;
  ${media.tablet`
    max-width: 360px;
  `}
  ${media.phone`
    max-width: 100%;
  `}
`
const ImageDemo = styled.img`
  position: absolute;    
  top: 6px;
  right: -208px;
  max-width: 692px;
  ${media.tablet`
    top: -74px;
    right: -139px;
    max-width: 524px;
  `}
  ${media.phone`
    display: none;
  `}
  /* Filthy hack to treat edge-cases... */
  ${({ pageTitle }) => pageTitle !== 'travelers' && `
    top: -170px;
    right: -1080px;
    max-width: 1550px;
  `}
`
const ButtonWrap = styled.div`
  margin: 0 -10px;
  ${media.phone`
    margin: 0 0 70px 0;
  `}
`

const Hero = ({ title, description, has_text_me_the_app, image, buttons }) => {
  const ctx = useContext(Store)
  const { colorScheme } = ctx
  const pageTitle = ctx.title

  return (
    <Wrap colorScheme={colorScheme}>
      <Container>
        <InnerWrap>
          <H1 children={title} />
          <Description children={description} />
            {has_text_me_the_app ? <TextMeTheApp /> : (
              <ButtonWrap>
                {buttons && buttons.map((val, ind) => <Button hm phoneBlock {...{ [val.style]: val.style }} key={ind} link={val.link && val.link.url} text={val.title} />)}
              </ButtonWrap>
            )}
        </InnerWrap>
        {image !== null && image.localFile !== null && <ImageDemo src={image.localFile.childImageSharp.fluid.src} pageTitle={pageTitle.toLowerCase()} /> }
      </Container>
    </Wrap>
  )
}

export default Hero
