import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Store from '../../utils/Store'
import { media } from '../Structure'
import Image from './Image'

const Wrap = styled.div`
  margin-right: 50px;
  position: relative;
`
const Bubble = styled.div`
  display: none;
  position: absolute;
  bottom: 100%;
  border-radius: 4px;
  box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.08);
  background: white;
  overflow: hidden;
  left: -15px;
  ${({ active }) => active && `
    display: block;
  `}
`
const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
  ${({ main }) => main && `
    padding: 10px 0;
    &:hover {
      background: inherit;
    }
  `}
  ${media.phone`
    ${({ main }) => main && ` padding: 15px 0; `}
  `}
`
const Flag = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 8px;
`
const Name = styled.div`
  font-size: 17px;
  line-height: 1.25;
  margin-right: 10px;
`
const Arrow = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #4a4a4a transparent transparent transparent;
  transition: transform .3s ease;
  ${({ active }) => active && `
    transform: rotate(180deg);
  `}
`

// Locales dictionary
const locales = {
  en_US: {
    name: 'English',
    flag: 'icon_flag_uk.png'
  },
  da_DK: {
    name: 'Dansk',
    flag: 'icon_flag_dk.png'
  }
}

const LanguageSwitcher = () => {
  const { wpml_translations, wpml_current_locale } = useContext(Store)

  const [active, setActive] = useState(false)

  const toggleActive = () => {
    setActive(!active)
  }

  return (
    <>
      <Wrap>
        <Bubble active={active}>
          <Row>
            <Flag>
              <Image src={locales[wpml_current_locale].flag} />
            </Flag>
            <Name>{locales[wpml_current_locale].name}</Name>
          </Row>
          {wpml_translations.map((val, ind) => (
            <Link key={ind} to={val.href} >
              <Row>
                <Flag>
                  <Image src={locales[val.locale].flag} />
                </Flag>
                <Name>{locales[val.locale].name}</Name>
              </Row>
            </Link>
          ))}
        </Bubble>
        <Row main onClick={toggleActive}>
          <Flag>
            <Image src={locales[wpml_current_locale].flag} />
          </Flag>
          <Name>{locales[wpml_current_locale].name}</Name>
          <Arrow active={active} />
        </Row>
      </Wrap>
    </>
  )
}

export default LanguageSwitcher
