import React from 'react'
import styled from 'styled-components'
import Helmet from "react-helmet"

import Store from '../utils/Store'
import GlobalStyle from '../utils/GlobalStyle'

import Header from '../components/sections/Header'
import Layout from './Layout'

import Footer from '../components/sections/Footer'
import FooterBottom from '../components/sections/FooterBottom'

const Wrap = styled.div`
  overflow-x: hidden;
  min-height: 100vh;
`

const Page = ({ pageContext, location }) => {
  const {
    wordpress_id,
    wordpress_parent,
    title,
    acf,
    yoast,
    wpml_translations,
    wpml_current_locale
  } = pageContext

  const defaultContext = {
    colorScheme: acf.color_scheme,
    location,
    title,
    wpml_translations,
    wpml_current_locale
  }

  return (
    <Store.Provider value={defaultContext}>
      <Wrap>
        <GlobalStyle />

        <Helmet>
            <meta charSet="utf-8" />
            <title>{yoast.title || title}</title>
            <meta name="description" content={yoast.metadesc} />
        </Helmet>

        <Header
          wordpress_id={wordpress_id}
          wordpress_parent={wordpress_parent}
          wpml_current_locale={wpml_current_locale}
        />

        <Layout data={acf.flexible_content_page} />

        <Footer />
        <FooterBottom />

      </Wrap>
    </Store.Provider>
  )
}

export default Page
