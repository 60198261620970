import React from 'react'
import styled from 'styled-components'

import { media } from '../Structure'
import { P2 } from '../Typography'
import icon_check from '../../images/icon_check.svg'

const Wrap = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`

const Row = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${media.phone`
    width: 100%;
  `}
  p {
    margin: 0;
  }
`

const Item = styled(P2)`
  margin: 0;
  ${media.phone`
    font-size: 17px;
  `}
`

const Icon = styled.div`
  width: 100%; 
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: url(${icon_check}) no-repeat center center;
  border-radius: 50%;
  margin-right: 10px;
  color: white;
  font-weight: bold;
  font-size: 17px;
`


const SimpleList = ({ data }) => (
  <Wrap>
    {data.map((val, ind) => (
      <Row key={ind} >
        <Icon />
        <Item children={val.text} />
      </Row>
    ))}
  </Wrap>
)

export default SimpleList
