import React from 'react'
import styled from 'styled-components'

import { Container, media } from '../Structure'
import { H2, P1 } from '../Typography'
import Button from '../elements/Button'
import gradient from '../../images/detail_gradient_2.svg'

const ROTATE_DEG = -8.5

const Wrap = styled.div`
  padding: 130px 0 200px;
  margin-bottom: -110px;
  background: #f7f9fc;
  color: #000;
  text-align: center;
  position: relative;
  transform-origin: 50% 50%;
  transform: rotate(${ROTATE_DEG}deg);
  box-shadow: 200px 0 #f7f9fc, -200px 0 #f7f9fc; 
  z-index: 500;
  ${media.tablet`
    padding: 80px 0 130px;
    margin-bottom: -57px;
  `}
  ${media.phone`
    padding: 20% 0;
    margin-bottom: -7%;
  `}
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    ${({ gradient }) => gradient && `
      background: url(${gradient}) no-repeat center bottom;
      background-size: cover;
    `}
  }
`

const InnerWrap = styled.div`
  transform-origin: 50% 50%;
  transform: rotate(${ROTATE_DEG * -1}deg);
`


const CTA = ({ title, description, buttons, style, mr }) => (
  <Wrap gradient={gradient}>
    <Container fluid>
      <InnerWrap>
        <H2 children={title} />
        <P1 children={description} />
        {buttons && buttons.map((val, ind) => (
          <Button hm phoneBlock {...{ [val.style]: val.style }} mr={mr} key={ind} link={val.link.url || ''} text={val.text} />
        ))}
      </InnerWrap>
    </Container>
  </Wrap>
)

export default CTA
