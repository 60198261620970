import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Store from '../../utils/Store'

import { media } from '../../components/Structure'

const Wrap = styled.div`
  display: block;
  color: black;
  ${media.phone`
    display: none;
  `}
`

const Crumb = styled(Link)`
  color: black;
`

const Breadcrumbs = () => {
  let { title } = useContext(Store)

  return (
    <Wrap>
      <Crumb to='/'>
        Home
      </Crumb>&nbsp;› {title}
    </Wrap>
  )
}

export default Breadcrumbs
